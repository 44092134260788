@mixin mdi-spacing-right($distance) {
  margin-right: ($distance * .5);
}

@mixin mdi-spacing($distance) {
  margin-left: ($distance * -.25);
  @include mdi-spacing-right($distance);
}

@if $enable-iconography {
  .alert.mdi::before {
    @include mdi-spacing($alert-padding-x);
  }

  .btn.mdi:not(:empty)::before {
    @include mdi-spacing-right($btn-padding-x);
  }

  .btn-lg.mdi:not(:empty)::before {
    @include mdi-spacing-right($btn-padding-x-lg);
  }

  .btn-sm.mdi:not(:empty)::before {
    @include mdi-spacing-right($btn-padding-x-sm);
  }

  .breadcrumb-item .mdi:not(:empty)::before {
    @include mdi-spacing-right($breadcrumb-item-padding);
  }

  .card-title.mdi:not(:empty)::before,
  .card-subtitle.mdi:not(:empty)::before,
  .card-link.mdi:not(:empty)::before {
    @include mdi-spacing-right($card-spacer-x);
  }

  .dropdown-item.mdi:not(:empty)::before {
    @include mdi-spacing($dropdown-item-padding-x);
  }

  .list-group-item.mdi:not(:empty)::before {
    @include mdi-spacing($list-group-item-padding-x);
  }

  .modal-title.mdi::before {
    @include mdi-spacing-right($spacer);
  }

  .nav-link.mdi:not(:empty)::before {
    @include mdi-spacing-right($nav-link-padding-x);
  }
  .navbar-brand.mdi:not(:empty)::before {
    @include mdi-spacing-right($navbar-brand-padding-y);
  }
}
