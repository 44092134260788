/*!
 * JCU CookBook v3.2.2 (https://cookbook.jcu.io)
 * Copyright 2017-2021 James Cook University
 */

// Required
@import "bootstrap/scss/functions";
// JCU CookBook variable setup
@import "variables";


@import "bootstrap/scss/variables";
@import "bootstrap/scss/utilities";
// Custom addtional Boostrap Utilities
@import "utilities/utilities";

//
// Bootstrap build process
//
@import "bootstrap/scss/bootstrap";

//
// JCU CookBook additions
//
@import "root";
@import "custom";
@import "iconography";
@import "utilities/text";
