// Buttons - increased colour contrast for a11y on light backrounds
.btn-outline-secondary {
  $btn-color: $gray-600;
  color: $btn-color;
  border-color: $btn-color;
}

// Close icon - increased colour contrast for a11y
.close {
  opacity: .75;

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

// Form Switch - rounded corners and large variant
.form-switch {
  .form-check-input {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: valid-radius($form-switch-border-radius);

    &-lg {
      width: $form-check-input-width * 3;
      height: $form-check-input-width * 1.5;
    }
  }
}
